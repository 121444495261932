.controls {
  text-align: center; }
  .controls a {
    cursor: pointer; }
  .controls .disabled {
    opacity: .5; }
  .controls .disabled {
    cursor: default; }
  .controls .disabled:hover {
    text-decoration: none; }

.fde {
  text-align: center; }
  .fde h4 {
    text-transform: capitalize; }
  .fde .date {
    font-size: small;
    opacity: .5; }
  .fde .page {
    max-width: 600px;
    min-height: 500px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 3px 6px 6px rgba(0, 0, 0, 0.23), 0 -2px 20px rgba(0, 0, 0, 0.1); }
  .fde .links {
    padding-top: 20px;
    height: 50px; }

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  h2 {
    font-size: 23px;
    margin-top: 10px; }
  h1 {
    font-size: 25px; }
  .fde .page {
    width: 98%; }
  .menu {
    padding-bottom: 20px; } }
